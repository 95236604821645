@font-face {
  font-family: 'syne';
  src        :
    url('./assets/fonts/syne.woff') format('woff'),
    url('./assets/fonts/syne.woff2') format('woff2'),
    url('./assets/fonts/syne.eot') format('eot'),
    url('./assets/fonts/syne.ttf') format('ttf');
}

* {
  font-family: 'syne';
  font-size  : 14pt;
  outline: none;
  letter-spacing: 1px;
}

html,
body {
  margin    : 0;
  padding   : 0;
  color     : #FFF;
  scroll-behavior: smooth;
}

main {
  padding-left: 110px;
}

@media only screen and (max-width: 959px) {
  main {
    padding: 55px 0px 0px 0px;
  }  

  * {
    font-size  : 11pt;
  }
}